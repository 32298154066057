import logo1 from "../assets/images/logo/logo-1.svg";
import logo2 from "../assets/images/logo/logo-2.svg";
import logo3 from "../assets/images/logo/logo-3.svg";
import logo4 from "../assets/images/logo/logo-4.svg";
import logo5 from "../assets/images/logo/logo-5.svg";
import logo6 from "../assets/images/logo/logo-6.svg";
import logo7 from "../assets/images/logo/logo-7.svg";
import logo8 from "../assets/images/logo/logo-8.svg";
import logo9 from "../assets/images/logo/logo-9.svg";
import logo10 from "../assets/images/logo/logo-10.svg";
import logo11 from "../assets/images/logo/logo-11.svg";
import logo12 from "../assets/images/logo/logo-12.svg";
import logo13 from "../assets/images/logo/logo-13.svg";
import logo14 from "../assets/images/logo/logo-14.svg";
import logo15 from "../assets/images/logo/logo-15.svg";
import logo16 from "../assets/images/logo/logo-16.svg";
import logo17 from "../assets/images/logo/logo-17.svg";
import logo18 from "../assets/images/logo/logo-18.svg";
import hole1 from "../assets/images/holes/hole-1.jpg"
import sp1 from "../assets/images/sp/sp-logo-1.svg"
import hole2 from "../assets/images/holes/hole-2.jpg"
import sp2 from "../assets/images/sp/sp-logo-2.svg"
import hole3 from "../assets/images/holes/hole-3.jpg"
import sp3 from "../assets/images/sp/sp-logo-3.svg"
import hole4 from "../assets/images/holes/hole-4.jpg"
import sp4 from "../assets/images/sp/sp-logo-4.svg"
import hole5 from "../assets/images/holes/hole-5.jpg"
import sp5 from "../assets/images/sp/sp-logo-5.svg"
import hole6 from "../assets/images/holes/hole-6.jpg"
import sp6 from "../assets/images/sp/sp-logo-6.svg"
import hole7 from "../assets/images/holes/hole-7.jpg"
import sp7 from "../assets/images/sp/sp-logo-7.svg"
import hole8 from "../assets/images/holes/hole-8.jpg"
import sp8 from "../assets/images/sp/sp-logo-8.svg"
import hole9 from "../assets/images/holes/hole-9.jpg"
import sp9 from "../assets/images/sp/sp-logo-9.svg"
import hole10 from "../assets/images/holes/hole-10.jpg"
import sp10 from "../assets/images/sp/sp-logo-10.svg"
import hole11 from "../assets/images/holes/hole-11.jpg"
import sp11 from "../assets/images/sp/sp-logo-11.svg"
import hole12 from "../assets/images/holes/hole-12.jpg"
import sp12 from "../assets/images/sp/sp-logo-12.svg"
import hole13 from "../assets/images/holes/hole-13.jpg"
import sp13 from "../assets/images/sp/sp-logo-13.svg"
import hole14 from "../assets/images/holes/hole-14.jpg"
import sp14 from "../assets/images/sp/sp-logo-14.svg"
import hole15 from "../assets/images/holes/hole-15.jpg"
import sp15 from "../assets/images/sp/sp-logo-15.svg"
import hole16 from "../assets/images/holes/hole-16.jpg"
import sp16 from "../assets/images/sp/sp-logo-16.svg"
import hole17 from "../assets/images/holes/hole-17.jpg"
import sp17 from "../assets/images/sp/sp-logo-17.svg"
import hole18 from "../assets/images/holes/hole-18.jpg"
import sp18 from "../assets/images/sp/sp-logo-18.svg"

const sponsors = [
    {
        order: 1,
        logo: logo1,
        name: "BancaStato",
        code: "bancastato",
        url: "/sponsor/1-bancastato",
        spLogo: sp1,
        hole: hole1
    },
    {
        order: 2,
        logo: logo2,
        name: "Fratelli Beretta",
        code: "fratelliberetta",
        url: "/sponsor/2-fratelli-beretta",
        spLogo: sp2,
        hole: hole2
    },
    {
        order: 3,
        logo: logo3,
        name: "Clovis Sail Yacht",
        code: "clovis",
        url: "/sponsor/3-clovis-sail-yacht",
        spLogo: sp3,
        hole: hole3
    },
    {
        order: 4,
        logo: logo4,
        name: "Bomio SA Elettrigilà",
        code: "bomiosa",
        url: "/sponsor/4-bomio-sa-elettrigila",
        spLogo: sp4,
        hole: hole4
    },
    {
        order: 5,
        logo: logo5,
        name: "SWICA",
        code: "swica",
        url: "/sponsor/5-swica",
        spLogo: sp5,
        hole: hole5
    },
    {
        order: 6,
        logo: logo6,
        name: "Consulca SA",
        code: "consulca",
        url: "/sponsor/6-consulca-sa",
        spLogo: sp6,
        hole: hole6
    },
    {
        order: 7,
        logo: logo7,
        name: "Yeldo",
        code: "yeldo",
        url: "/sponsor/7-yeldo",
        spLogo: sp7,
        hole: hole7
    },
    {
        order: 8,
        logo: logo8,
        name: "Zurich",
        code: "zurich",
        url: "/sponsor/8-zurich",
        spLogo: sp8,
        hole: hole8
    },
    {
        order: 9,
        logo: logo9,
        name: "Gioielleria Kurz - Lugano",
        code: "kurz",
        url: "/sponsor/9-kurz",
        spLogo: sp9,
        hole: hole9
    },
    {
        order: 10,
        logo: logo10,
        name: "Mino Caggiula Architects",
        code: "mino-caggiula-architects",
        url: "/sponsor/10-mino-caggiula-architects",
        spLogo: sp10,
        hole: hole10
    },
    {
        order: 11,
        logo: logo11,
        name: "BMW Emil Frey - Lamone",
        code: "bmw-emil-frey",
        url: "/sponsor/11-bmw-emil-frey",
        spLogo: sp11,
        hole: hole11
    },
    {
        order: 12,
        logo: logo12,
        name: "New Line",
        code: "newline",
        url: "/sponsor/12-newline",
        spLogo: sp12,
        hole: hole12
    },
    {
        order: 13,
        logo: logo13,
        name: "Bazzana SA",
        code: "bazzana",
        url: "/sponsor/13-bazzana",
        spLogo: sp13,
        hole: hole13
    },
    {
        order: 14,
        logo: logo14,
        name: "Kartell - Lugano",
        code: "kartell",
        url: "/sponsor/14-kartell",
        spLogo: sp14,
        hole: hole14
    },
    {
        order: 15,
        logo: logo15,
        name: "Delcò Mobili",
        code: "delco",
        url: "/sponsor/15-delco",
        spLogo: sp15,
        hole: hole15
    },
    {
        order: 16,
        logo: logo16,
        name: "A-Club",
        code: "aclub",
        url: "/sponsor/16-aclub",
        spLogo: sp16,
        hole: hole16
    },
    {
        order: 17,
        logo: logo17,
        name: "Smart Gorla Services",
        code: "smartgorla",
        url: "/sponsor/17-smartgorla",
        spLogo: sp17,
        hole: hole17
    },
    {
        order: 18,
        logo: logo18,
        name: "Wetag Consulting",
        code: "wetag",
        url: "/sponsor/18-wetag",
        spLogo: sp18,
        hole: hole18
    },
]

export const utils = {
    sponsors
}
